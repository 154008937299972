<template>
  <div>
    <a-modal width="700px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form ref="formRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :model="formState" :rules="rules">
        <a-form-item label="菜单名称：" name="menuName">
          <a-input v-model:value="formState.menuName" />
        </a-form-item>
        <a-form-item label="上级菜单：">
          <a-tree-select v-model:value="formState.pid" allowClear style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="menuTree" :replaceFields="replaceFields" placeholder="请选择"></a-tree-select>
        </a-form-item>
        <a-form-item label="菜单url：" name="path">
          <a-input v-model:value="formState.path" style="width: 370px" />
        </a-form-item>
        <a-form-item label="权限：" name="permission">
          <a-input v-model:value="formState.permission" style="width: 370px" />
        </a-form-item>
        <a-form-item label="菜单图标：">
          <a-input v-model:value="formState.icon" style="width: 370px" />
        </a-form-item>
        <a-form-item label="排序：">
          <a-input-number id="inputNumber" v-model:value="formState.sort" :min="0" />
        </a-form-item>
        <a-form-item label="组件：" name="component">
          <a-input v-model:value="formState.component" style="width: 370px" />
        </a-form-item>
        <a-form-item label="路由名称：" name="routeName">
          <a-input v-model:value="formState.routeName" style="width: 370px" />
        </a-form-item>
        <a-form-item label="是否显示：" name="isShow">
          <a-radio-group v-model:value="formState.isShow" button-style="solid">
            <a-radio-button :value="true">显示</a-radio-button>
            <a-radio-button :value="false">隐藏</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="路由重定向：">
          <a-input v-model:value="formState.redirect" style="width: 370px" />
        </a-form-item>
        <a-form-item label="菜单类型：" name="menuType">
          <a-select ref="select" v-model:value="formState.menuType" style="width: 120px" :options="options">
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { menu_post, menu_put, menu_del, menuTree } from '@/api/system'
const form = {
  menuName: null,
  pid: null,
  path: null,
  permission: null,
  icon: null,
  sort: null,
  component: null,
  routeName: null,
  isShow: null,
  redirect: null,
  menuType: null
}
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      options: [
        {
          value: 'dir',
          label: '目录'
        },
        {
          value: 'menu',
          label: '菜单'
        },
        {
          value: 'button',
          label: '按钮'
        }
      ],
      replaceFields: {
        title: 'menuName',
        key: 'menuId',
        value: 'menuId'
      },
      visible: false,
      menuTree: [],
      formState: {
        ...form
      },
      rules: {
        menuName: [{ required: true, message: '不能为空', trigger: 'change' }],
        isShow: [
          {
            required: true,
            message: '不能为空',
            trigger: 'change',
            transform: (value) => {
              if (value) {
                return value.toString()
              } else {
                return value.toString()
              }
              return value
            }
          }
        ],
        permission: [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        path: [{ required: true, message: '不能为空', trigger: 'change' }],
        component: [{ required: true, message: '不能为空', trigger: 'change' }],
        routeName: [{ required: true, message: '不能为空', trigger: 'change' }],
        menuType: [
          {
            required: true,
            message: '不能为空',
            trigger: 'change',
            transform: (value) => {
              if (value) {
                return value.toString()
              }
              return value
            }
          }
        ]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          menu_post(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.cancel()
            this.$parent.refresh()
          })
        } else {
          menu_put(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
      }
      menuTree().then((res) => {
        this.menuTree = res.data
      })
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    